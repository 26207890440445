import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProductsView from '../views/ProductsView.vue';
import ContactView from '../views/ContactView.vue';
import ProductsDetailView from '../views/ProductsDetailView.vue';
import AboutView from '../views/AboutView.vue';
import MobileHomeView from '../views/mobile/MobileHomeView.vue'; // 假设你有移动端的视图
import MobileProductsView from '../views/mobile/MobileProductsView.vue';
import MobileContactView from '../views/mobile/MobileContactView.vue';
import MobileIotView from '../views/mobile/MobileIotView.vue';
import MobileProductsDetail from '../views/mobile/MobileProductsDetailView.vue';
import MobileAboutView from '../views/mobile/MobileAboutView.vue';
import { isMobileDevice } from '../utils/public';

// 判断是否是移动设备
const isMobile = isMobileDevice();

// 根据设备类型选择不同的路由配置
const routes = isMobile
  ? [
      {
        path: '/',
        name: 'MobileHome',
        component: MobileHomeView
      },
      {
        path: '/products',
        name: 'MobileProducts',
        component: MobileProductsView
      },
      {
        path: '/contact',
        name: 'MobileContact',
        component: MobileContactView
      },
      {
        path: '/productsDetail',
        name: 'MobileProductsDetail',
        component: MobileProductsDetail
      },
      {
        path: '/iot',
        name: 'MobileIotView',
        component: MobileIotView
      },
      {
        path: '/about',
        name: 'MobileAboutView',
        component: MobileAboutView
      }
    ]
  : [
      {
        path: '/',
        name: 'Home',
        component: HomeView
      },
      {
        path: '/products',
        name: 'Products',
        component: ProductsView
      },
      {
        path: '/productsDetail',
        name: 'ProductsDetail',
        component: ProductsDetailView
      },
      {
        path: '/contact',
        name: 'Contact',
        component: ContactView
      },
      {
        path: '/about',
        name: 'AboutView',
        component: AboutView
      }
    ];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
