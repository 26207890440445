<template>
    <div style="font-family: A;">
        <div style="font-size: 2rem;font-weight: 700;color: #1296db;">慧光路灯智能云平台</div>
        <div style="  display: flex; justify-content: center;padding: 0px 10%;font-size: 18px;">
            <div style="width: 50%;"> <img
                    src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/lightplatform.png" width="80%">
                <div>慧光路灯智能云平台</div>
            </div>
            <div style="width: 50%;"> <img
                    src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/%E9%9B%86%E4%B8%AD%E5%99%A8.png"
                    width="80%">
                <div>照明远程集中测控终端</div>
            </div>
        </div>
        <div style="padding: 60px 0%;text-align: left; font-size: 1rem;color: #1cabff;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;舜铭慧光路灯智能云平台结合照明远程集中测控终端，可实时采集路灯电压、电流、电量等参数。通过对路灯控制通道参数进行设置，实现了线路检测、远程控制、精准计量的效果。从而解决了路灯管理人员日常运行管理维护繁琐、巡检及故障排查不及时等诸多问题。
        </div>

        <div style="  display: flex; justify-content: center;padding: 30px 10%;font-size: 18px;">
            <div style="width: 80%;"> <img
                    src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/ludengkongzhiqi.png" width="70%">
                <div>路灯控制器</div>
            </div>
            <div style="width: 80%;"> <img src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/tiapjieqi.png"
                    width="70%">
                <div>单灯控制器</div>
            </div>
        </div>
        <div style="padding: 60px 0%;text-align: left; font-size: 1rem;color: #1cabff;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 再搭配路灯控制器以及单灯控制器，可快速灵活设置并下发节能策略，在保证正常照明运行情况下分时段调节亮灯功率，实测50%功率下人眼几乎观测不到亮度变化，综合节省照明用电30%-50%。</div>

        <!-- 产品特性部分 -->
        <div style="font-size: 2rem;font-weight: 700;color: #1296db;">产品特性</div>
        <div class="product-jj">
            <div style="width: 100%;">
                <img src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/zhuyetongji.png" width="70%" />
            </div>
            <div style="width: 100%;">
                <div style="margin-right: 0px;">
                    <div>
                        <div class="product-kind-name">照明故障 实时推送</div>
                        <div class="product-text">
                            通过对线路电压电流的分析，在线实时评估线路状况，能够及时的向负责人推送异常信息定位故障，减少巡检维护量。对各个维度的用电量情况进行统计分析。为节能决策提供依据。</div>
                    </div>
                </div>
            </div>
           
        </div>
        <div class="product-jj">
            <div style="width: 100%;">
                <img src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/ludengjieneng.png" width="70%" />
            </div>
            <div style="width: 100%;">
                <div style="margin-right: 0px;">
                    <div>
                        <div class="product-kind-name">节省城市路灯70%的耗电量</div>


                        <div class="product-text">
                            在保证城市路灯照明质量且不影响来往行人及车辆正常通行的情况下，根据夜间不同时间段可对路灯进行智能化管控，对路灯亮度进行调节，降低功率从而达到节能效果。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="product-jj">
            <div style="width: 100%;">
                <img src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/ludengluansheng.png" width="70%" />
            </div>
            <div style="">
                <div style="margin-right: 0px;">
                    <div>
                        <div class="product-kind-name">灵活控制 + 数字孪生</div>
                        <div class="product-text">
                            可灵活设置多种亮灯模式，如根据地理位置、光照度、节假日、季节变化和自定义时间段等。同时，结合数字孪生技术，实现更精确的模拟与控制，优化照明效果并提升管理效。</div>
                    </div>
                </div>
            </div>
           
        </div>

        <div style="font-size: 2rem;font-weight: 700;color: #1296db;margin-top: 100px;">解决痛点</div>
   
        <div class="choose-style">
            <div><img src="../../assets/jieneng.png" class="image-style"></div>
            <div class="choose-text">  高能耗、成本高 ：通过智能控制和调光技术，根据环境光照强度、时间段、交通状况等动态调整亮度，实现节能降耗，降低电力开支。</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/weihu.png" class="image-style"></div>
            <div class="choose-text">管理与维护困难 ：提供远程监控和故障报警功能，实时检测灯具的运行状态，自动上报故障信息，快速定位和安排维护，大幅提高维护效率。</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/AI.png" class="image-style"></div>
            <div class="choose-text"> 照明控制不智能 ：智能感应控制，通过环境光传感器、交通监测设备等自动调节照明开关与亮度，实现按需照明，提升灯光的利用效率。</div>
        </div>

        <div class="choose-style">
            <div><img src="../../assets/city.png" class="image-style"></div>
            <div class="choose-text"> 智能城市建设需求 ：智慧路灯可以作为智能城市的重要节点，结合智能交通、智能停车、5G通信基站等功能，成为智慧城市建设的基础设施之一，推动智慧城市的发展。</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/gongxiang.png" class="image-style"></div>
            <div class="choose-text">   信息孤岛和资源浪费 ： 智慧路灯作为城市物联网平台的节点，可与其他系统（如交通管理、环境监测、5G基站等）集成，实现数据共享和资源优化，提升整体城市运营效率。</div>
        </div>

    </div>

</template>
<script></script>
<style scoped>
.product-jj {
    margin-top: 50px;
}

.product-kind-name {
    font-size: 20px;
    color: #1ba2ff;
    margin-top: 10px;
}

.product-text {
    padding: 30px 0px;
    color: #1ba2ff;
    text-align: left;
    font-size: 18px;
}

.tongdian {
    display: flex;
    margin-bottom: 100px;
    margin-left: 5%;
}

.big-title {
    font-size: 60px;
    margin-top: 160px;
    font-family: initial;
    font-weight: bold;
    color: #1296db;
}
    .choose-style {
        margin: 80px 0px;

        /* 启用 flexbox 布局 */
        .image-style {
            width: 60%;
        }
        .choose-text {
            margin-top: 10px;
            text-align: left;
            font-size: 1rem;
            color: #1296db;
        }
    }
</style>