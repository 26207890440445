<template>
    <div class="about-view-style">
        <div style="position: relative; margin-bottom: 180px;">
            <div><img style="width: 100%;"
                    src="https://qiniu.shunmingelectronic.com/guanwang/product/%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC.png" />
            </div>
            <div class="home-page-text">
                <div style="padding: 0px 20%;   color: #09bfff;;">
                    关于我们
                </div>
            </div>
        </div>
        <div class="about-title">关于舜铭</div>
        <div class="container">
            <div class="left">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/huiyi.jpg" />
            </div>
            <div class="right">
                <div style=" margin-top: 50px; font-size: 24px; color: #1296db; font-family: math;text-align: left;">
             
                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 河南舜铭电子信息科技有限公司是一家新成立的科技型企业，位于河南省洛阳市高新区。         我们的团队专注于电子信息和物联网领域的研发，致力于为客户提供全面的智能化与信息化解决方案，包括物联网平台、仪器仪表PCB、智能传感器、物联网设备、人工智能和边缘计算等服务。并以精益求精、合作共赢为理念，联合传统行业的同仁，通过脚踏实地的研发和不断地创新实现优势互补，将数字化和信息化的理念整合到传统行业中，共同提升产品竞争力，达到1+1大于2的效果。
                </div>
            </div>
        </div>
        <div class="container">
            <div class="left">
                <div style=" margin-top: 150px; font-size: 24px; color: #1296db; font-family: math;text-align: left;">
                    此外，我们还将先进的 4G 技术融入我们的仪器，实现无缝数据传输到物联网平台。这可以实现实时监控和管理，确保我们的客户从增强的连接性和运营效率中受益。
                </div>

            </div>
            <div class="right">
                <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/platform.jpg" />
            </div>
        </div>
        <div class="about-title">为什么选择我们
        </div>
        <div class="choose-style">
            <div><img src="../assets/chuanx.png" class="image-style"></div>
            <div class="choose-text">创新：我们不断创新，将 4G 技术融入我们的仪器中，实现与物联网平台的无缝连接并提供智能解决方案。</div>
        </div>
        <div class="choose-style">
            <div><img src="../assets/zhuanye.png" class="image-style"></div>
            <div class="choose-text">专业：我们在流量计的研究和生产方面拥有多年的经验，可提供高质量的产品和解决方案。</div>
        </div>

        <div class="choose-style">
            <div><img src="../assets/fuwu.png" class="image-style"></div>
            <div class="choose-text">优质服务：我们不仅提供产品，还提供全面的技术支持和售后服务，确保为客户提供无忧的体验。</div>
        </div>
        <div class="choose-style">
            <div><img src="../assets/kehu.png" class="image-style"></div>
            <div class="choose-text">以客户为中心：我们优先考虑客户需求，提供定制解决方案以帮助实现更高的运营效率。</div>
        </div>
    </div>
</template>
<script></script>
<style scoped>
.about-view-style {
    .about-title {
        font-size: 60px;
        margin-top: 160px;
        font-family: initial;
        font-weight: bold;
        color: #1296db;
    }

    .home-page-text {
        position: absolute;
        top: 40%;
        width: 100%;
        color: white;
        font-size: 50px;
        font-weight: bold;
        text-align: center
    }

    .container {
        display: flex;
        margin: 100px 10%;
        height: 600px;
        background-color: #F1F1F1;
    }

    .left {
        width: 50%;
        background-color: white;
        /* 用于演示 */
    }

    .right {
        padding-left: 150px;
        width: 50%;
        background-color: white;
    }

    .choose-style {
        text-align: left;
        height: 150px;
        margin: 80px 250px 80px 300px;
        display: flex;

        /* 启用 flexbox 布局 */
        .image-style {
            width: auto;
            height: 100%;
        }

        .choose-text {
            align-items: center;
            font-size: 30px;
            display: flex;
            margin-left: 100px;
            color: #1296db;

        }

    }
}
</style>