<template>
    <div style="font-family: A;color: #1296db">
        <div style="width:100%;padding-top: 120px; "> <img
                src=" https://qiniu.shunmingelectronic.com/guanwang/IST_24519_105024.jpg" width="70%">
        </div>
        <div style="padding: 60px 0%;text-align: left; font-size: 22px;color: #1296db;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            在数字化、信息化和智能化快速发展的关键时期，本公司致力于为传统行业提供创新的物联网解决方案。帮助传统行业提升效率和竞争力。依托强大的物联网平台以及RTU等技术，我们能够实时采集、传输、存储和分析设备数据，实现精准的环境监测、设备管理和远程控制。并通过自主研发的低功耗传感器，确保设备在长时间运行中保持高效、稳定的性能，使您可以在数字世界中实时监控和分析实际运行状况，提前发现问题并进行优化调整。这不仅提高了系统的可靠性和效率，还为决策提供了强有力的数据支持。
        </div>
        <div style="  display: flex; justify-content: center;padding: 0px 10%;font-size: 18px;gap:10%">
            <div style="width:40%"> <img
                    src="https://qiniu.shunmingelectronic.com/irrigation/image/wulianwangshengji.png" width="100%">
            </div>
            <div style="width:40%"> <img
                    src="https://qiniu.shunmingelectronic.com/irrigation/image/%E5%86%9C%E7%94%B0.png" width="100%">
            </div>
        </div>
        <div style="font-size: 25px;">水库罐区数据孪生云平台</div>

        <div class="big-title" style="margin-top: 150px;">涉及领域</div>
        <div class="choose-style">
            <div><img src="../../assets/sz.png" class="image-style"></div>
            <div class="choose-text">工业厂区数据可视化：通过传感器、PLC、SCADA系统采集数据，并以图表和仪表盘形式实时展示关键设备和运营状态，助力高效监控和决策。</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/nt.png" class="image-style"></div>
            <div class="choose-text">农田建设管护服务及灌溉能源统计：提供农田基础设施建设与维护，监控灌溉系统的运行情况，统计和优化灌溉用水及能源消耗，确保资源高效利用和农业生产的可持续性。</div>
        </div>

        <div class="choose-style">
            <div><img src="../../assets/gs.png" class="image-style"></div>
            <div class="choose-text">住宅和商业物业能源预付费管理：为住宅和商业物业提供能源预付费解决方案，用户可通过预付费系统管理水电等能源使用，实时监控消费情况，提升物业管理效率。</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/shuili.png" class="image-style"></div>
            <div class="choose-text">水利行业数据监测及可视化：通过雨量筒、雷达流量计等主流设备实时监测水文数据，利用可视化平台将这些数据以图表和仪表盘形式呈现，助力水资源管理、洪水预警及水利工程的高效决策与运维。</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/cs.png" class="image-style"></div>
            <div class="choose-text">城市基础设施数字化：通过传感器网络、物联网平台和数据分析技术，将城市的供水、供电、交通等基础设施进行数字化升级，实现实时监控、智能管理和高效运营，提升城市的运行效率和居民生活质量。</div>
        </div>

    </div>
</template>
<script></script>
<style scoped>
.big-title {
    font-size: 60px;
    margin-top: 160px;
    font-family: initial;
    font-weight: bold;
    color: #1296db;
}
    .choose-style {
        text-align: left;
        height: 150px;
        margin: 80px 0px;
        display: flex;

        /* 启用 flexbox 布局 */
        .image-style {
            width: auto;
            height: 100%;
        }

        .choose-text {
            align-items: center;
            font-size: 24px;
            display: flex;
            margin-left: 100px;
            color: #1296db;
        }
    }
</style>