<template>
  <div id="app">
    <PageIndex />
  </div>
</template>

<script>
import PageIndex from "@/layout/MainIndex.vue";
import axios from 'axios';

export default {
  name: "App",
  components: { PageIndex },
  methods: {
    loginLog() {
      axios.post('website/website', { actionType: 'loginLog', websiteType: 1 })
        .then(response => {
          let result = response.data;
          console.log(result);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  },
  created() {
  },
  mounted() {
    this.loginLog();
  }
};
</script>

<style></style>
