<template>
    <div class="iot-view-style">
        <div style="position: relative;">
            <div><img style="width: 100%;"
                    src="https://qiniu.shunmingelectronic.com/guanwang/product/IST_28936_07550.jpg" /></div>
            <div class="home-page-text">
                <div style="padding: 0px 10%;text-align: left;   color: #fff0d9;">We provide you with advanced flow
                    meter products and integrated IoT solutions, making your data collection and device configuration
                    more convenient.</div>
            </div>
        </div>

        <div class="big-title">Smart Monitoring
            Real-Time Control</div>
        <div><img style="width: 60%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/shyetongji.png" />
        </div>
        <div class="small-title">Our unique IoT system
            utilizes 4G modules to enable efficient transmission of flow meter data, allowing you to monitor your device
            status and data in real-time, anytime, anywhere.
        </div>
        <div><img style="width: 60%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/TONGJI.png" /></div>
        <div class="small-title">The IoT platform
            supports multidimensional data analysis,
            automatically generating detailed reports and graphical pages to help you easily make data-driven decisions.
        </div>
        <div><img style="width: 60%;"
                src="https://qiniu.shunmingelectronic.com/guanwang/product/%E5%B1%8F%E5%B9%95zongji.png" /></div>
        <div class="big-title">Digital Twin Technology Personalized Scenario Design</div>
        <div><img style="width: 60%;" src="https://qiniu.shunmingelectronic.com/guanwang/luansheng1.png" /></div>
        <div class="small-title">We have introduced advanced Digital Twin technology, enabling highly personalized scenario design for our
            customers. By precisely mapping real-world data, you can intuitively simulate and optimize equipment
            operation in a virtual environment, customizing solutions that meet your unique needs. This technology not
            only enhances operational convenience but also helps you maximize equipment performance and efficiency.
        </div>
        <div><img style="width: 60%;" src="https://qiniu.shunmingelectronic.com/guanwang/luansheng2.png" /></div>
        <div class="big-title" >Flexible and Efficient Customer Support</div>
        <div class="small-title">To better support distributors, our platform offers distributor privilege accounts. Distributors can not only assign accounts to end users, enabling flexible permission management, but also customize the platform's company logo and title according to their business needs, enhancing brand recognition and customer satisfaction.
        </div>
        <div class="big-title">Platform Key Features</div>
        <div class="choose-style">
            <div><img src="../../assets/jiankong.png" class="image-style"></div>
            <div class="choose-title">Real-Time Monitoring</div>
            <div class="choose-text">Continuously monitor device operation data and status to ensure safety and efficient performance.</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/tongjitu.png" class="image-style"></div>
            <div class="choose-title">Multidimensional Statistics</div>
            <div class="choose-text">Provide comprehensive flow data analysis to help you gain deep insights into flow trends and usage.</div>
        </div>

        <div class="choose-style">
            <div><img src="../../assets/baogao.png" class="image-style"></div>
            <div class="choose-title">Report Generation</div>
            <div class="choose-text">We provide not just products but also comprehensive technical
                support and after-sales service, ensuring a worry-free experience for our customers.</div>
        </div>
        <div class="choose-style">
            <div><img src="../../assets/changjing.png" class="image-style"></div>
            <div class="choose-title">Personalized Scenario Design</div>
            <div class="choose-text">Utilize Digital Twin technology to create customized solutions that meet customer needs, optimizing device operation and management.</div>
        </div>
        <div style="margin: 100px 20px; display: flex; justify-content: center;" ref="videoContainer">
            <video ref="videoElement" style="width: 80%;" controls muted>
                <source src="https://qiniu.shunmingelectronic.com/guanwang/mymeter.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      hasUserInteracted: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleUserInteraction);
    this.createIntersectionObserver();
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleUserInteraction);
  },
  methods: {
    handleUserInteraction() {
      this.hasUserInteracted = true;
    },
    createIntersectionObserver() {
      const videoElement = this.$refs.videoElement;

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && this.hasUserInteracted) {
            videoElement.play().catch(error => {
              console.log("Auto-play was prevented: ", error);
            });
          } else {
            videoElement.pause();
          }
        });
      }, {
        threshold: 0.5
      });

      observer.observe(videoElement);
    }
  }
};</script>
<style scoped>
 .home-page-text {
        position: absolute;
        top: 15%;
        width: 100%;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        text-align: center
    }

    .big-title {
        font-size: 1.5rem;
        margin-top: 80px;
        margin-bottom: 10px;
        font-family: initial;
        font-weight: bold;
        color: #1296db;
    }

    .small-title {
        padding: 10px 30px;
        font-size: 1rem;
        color:#1296db;
        text-align: left;
    }
    .choose-style {
        text-align: center;
        margin: 60px 0px;
    }
    .choose-title {
            margin-bottom: 30px;
            font-size: 2rem;
            color: #1296db;
            font-family: fantasy;
        }

        /* 启用 flexbox 布局 */
        .image-style {
            width: 40%;
            height: auto;
            margin-bottom: 40px;
        }

        .choose-text {
            font-size: 20px;
            color: #1296db;
            text-align: left;
            padding: 10px 10%;

        }
</style>