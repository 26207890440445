<template>
  <div>
    <div>
      <img style="width: 100%;" src="https://qiniu.shunmingelectronic.com/guanwang/product/lianxiwomen.jpg" />
    </div>
    <div class="home-page-text">
      <div style="padding: 0px 20%;   color: #09bfff;">联系我们</div>
    </div>
    <div style="display: flex; padding: 50px 15%;">
      <div style="margin-bottom: 80px; color: rgb(134 134 134);width: 50%; text-align: center;">
        <div style="font-size: 20px;margin-top: 20px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">公司名称:</div> <span>河南舜铭电子信息科技有限公司</span>
        </div>
        <div style="font-size: 20px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">公司地址:</div>
          <span>中国（河南）自由贸易试验区洛阳片区（高新）丰华路1号803-3</span>
        </div>
        <div style="font-size: 20px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">联系电话:</div> <span>
            0379-63220552 &nbsp;&nbsp;&nbsp;+86&nbsp;16662992205</span>
        </div>
        <div style="font-size: 20px;margin-top: 40px; font-weight: 700;text-align: left;">
          <div style="margin-bottom: 10px;color: rgb(94 196 231);">邮箱:</div> <span>
            bright_xu@sina.cn</span>
        </div>
      </div>
      <div style="width: 50%; display: flex; justify-content: right; margin-top: 20px;">
        <div style="width: 40%;">
          <div style="font-size: 20px;margin-bottom: 30px;">微信号</div>
          <div><img src="../assets/fswxh.png" width="90%" /></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ContactView'
}
</script>
<style scoped>
.home-page-text {
  position: absolute;
  top: 15%;
  width: 100%;
  color: white;
  font-size: 50px;
  font-weight: bold;
  text-align: center
}
</style>