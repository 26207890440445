<template>
    <div style="font-family: A;color: #1296db">
        <div style="font-size: 36px;font-weight: 700;color: #1296db;">智控仪表数据云平台</div>
        <div style="  display: flex; justify-content: center;padding: 0px 10%;font-size: 18px;gap:10%">
            <div style="width:40%"> <img src="https://qiniu.shunmingelectronic.com/guanwang/guanwangmeter.png"
                    width="100%">
                <div>慧光路灯智能云平台</div>
            </div>
            <div style="width:40%"> <img src="https://qiniu.shunmingelectronic.com/guanwang/guanwangmeterpt.png"
                    width="100%">
                <div>照明远程集中测控终端</div>
            </div>
        </div>
        <div style="padding: 60px 0%;text-align: left; font-size: 22px;color: #1296db;">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 本公司的核心产品涵盖电磁流量计转换器系列，包括电池供电型、两线制、冷
            热计量专用、太阳能供电型电磁流量计转换器等多种型号。我们的电磁流量计转换器以高精度、高线性度和卓越的稳定性为特色。特别是电池供电电磁流量计，
            集成了GPRS和技术，支持数据上传至云平台，便于数据管理和服务。
        </div>

        <div style="display: flex;">
            <div style="width: 100%;"><img src="https://qiniu.shunmingelectronic.com/guanwang/biaotou.png"
                    width="80%" /></div>
        </div>
        <div style="font-size: 20px;font-weight: 700;color: #1296db;margin-bottom: 30px;">一体式电磁流量计转换器</div>

        <div style="font-size: 20px;font-weight: 700;color: #9b9b9b;margin-bottom: 160px;">输入:24VDC/220VAC/3.6V
            &nbsp;&nbsp;&nbsp; 输出:4-20mA/RS485/Pulse</div>
        <div style="display: flex;margin-bottom: 160px;">
            <div style="width: 100%;">
                <img src="https://qiniu.shunmingelectronic.com/guanwang/fenti.png" width="50%" />
                <div style="font-size: 20px;font-weight: 700;color: #1296db;margin-top: 40px;">分体式电磁流量计转换器</div>
            </div>
            <div style="width: 100%;">
                <img src="https://qiniu.shunmingelectronic.com/guanwang/dianchi.png" width="50%" />
                <div style="font-size: 20px;font-weight: 700;color: #1296db;margin-top: 40px">太阳能电磁流量计转换器</div>
            </div>
        </div>

        <div style="">
            <div style="font-size: 40px;margin-bottom: 50px;">应用场景</div>
            <div class="use">
                <div style="width: 25%;text-align: left;">水处理与供水:</div>
                <div style="width:75%;text-align: left;">用于市政供水和污水处理的精确流量测量。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">化工行业:</div>
                <div style="width:75%;text-align: left;">测量腐蚀性液体的流量，如酸、碱等。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">食品与饮料:</div>
                <div style="width:75%;text-align: left;">测量牛奶、果汁等食品液体。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">制药行业:</div>
                <div style="width:75%;text-align: left;">用于药液流量的精确控制。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">冶金与矿业:</div>
                <div style="width:75%;text-align: left;">测量矿浆、泥浆等导电液体的流量。</div>
            </div>
            <div class="use">
                <div style="width: 25%;text-align: left;">能源与石油:</div>
                <div style="width:75%;text-align: left;">应用于发电厂和石油开采中的流量测量。</div>
            </div>



            <div class="product-jj">
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>
                            <div class="product-text">
                                独特的物联网系统利用4G模块实现流量计数据的高效传输，让您随时随地实时监控设备状态和数据。物联网平台支持多维数据分析，自动生成详细报告和图形页面，帮助您通过数据驱动的做出更精准决策。
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 60%;">
                    <img src="https://qiniu.shunmingelectronic.com/guanwang/product/%E5%B1%8F%E5%B9%95zongji.png"
                        width="70%" />
                </div>
            </div>

            <div class="product-jj">
                <div style="width: 60%;">
                    <img src="https://qiniu.shunmingelectronic.com/guanwang/product/shyetongji.png" width="70%" />
                </div>
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>


                            <div class="product-text">
                                先进的数字孪生技术，为客户提供高度个性化的场景设计。精确映射真实世界的数据，您可以在虚拟环境中定制您独特的需求。该技术不仅提高了操作的便利性，还可以帮助您最大限度地提高设备的性能和效率。
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-jj">
                <div style="display: flex;  align-items: center;width: 40%;justify-content: center;">
                    <div style="margin-right: 0px;">
                        <div>
                            <div class="product-text">
                                为了更好地服务支持我们的客户，本平台为经销商提供特殊权限账号，经销商不仅可以将账号分配给终端用户，实现灵活的权限管理，还可以根据业务需求自定义平台的公司logo和标题，提升品牌认知度和客户满意度。
                                同时平台还提供线上售后服务，终端用户可以通过移动端拍摄视频、照片上报故障详情。售后人员可在平台直接处理现场问题，大幅度节省沟通成本。
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 60%;">
                    <img src="https://qiniu.shunmingelectronic.com/guanwang/cpmh-266095f07g.jpg" width="70%" />
                </div>
            </div>
            <!-- <div style="font-size: 26px;"><span style="width: 160px;">水处理与供水:</span><span>用于市政用水和工厂</span></div> -->
        </div>


        <div style="font-size: 40px;margin-bottom: 50px;margin-top: 100px;">平台特性</div>
        <div class="use">
            <div style="width: 25%;text-align: left;">实时监控:</div>
            <div style="width:75%;text-align: left;">持续监控设备运行数据和状态，确保高效安全的性能。</div>
        </div>
        <div class="use">
            <div style="width: 25%;text-align: left;">多维统计:</div>
            <div style="width:75%;text-align: left;">提供全面的流量数据分析，帮助您深入了解流量趋势和使用情况。</div>
        </div>
        <div class="use">
            <div style="width: 25%;text-align: left;">报表生成:</div>
            <div style="width:75%;text-align: left;">自动生成详细报表和图形页面，支持数据可视化和业务优化。</div>
        </div>
        <div class="use">
            <div style="width: 25%;text-align: left;">个性化场景设计:</div>
            <div style="width:75%;text-align: left;">利用数字孪生技术，打造符合客户需求的定制化解决方案，优化设备运行和管理。</div>
        </div>


        <div style="margin: 100px 20px; display: flex; justify-content: center;" ref="videoContainer">
            <video ref="videoElement" style="width: 60%;" controls muted>
                <source src="https://qiniu.shunmingelectronic.com/guanwang/mymeter.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            hasUserInteracted: false,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleUserInteraction);
        this.createIntersectionObserver();
    },
    beforeUnmount () {
        window.removeEventListener('scroll', this.handleUserInteraction);
    },
    methods: {
        handleUserInteraction() {
            this.hasUserInteracted = true;
        },
        createIntersectionObserver() {
            const videoElement = this.$refs.videoElement;

            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && this.hasUserInteracted) {
                        videoElement.play().catch(error => {
                            console.log("Auto-play was prevented: ", error);
                        });
                    } else {
                        videoElement.pause();
                    }
                });
            }, {
                threshold: 0.5
            });

            observer.observe(videoElement);
        }
    }
};
</script>
<style scoped>
.product-jj {
    display: flex;
    justify-content: center;
    margin-top: 150px;
}

.product-kind-name {
    font-size: 28px;
    color: #1296db;
}

.product-text {
    padding: 50px 0px;
    color: #1296db;
    text-align: left;
    font-size: 20px;
}

.use {
    font-size: 26px;
    display: flex;
    justify-content: center;
    padding: 12px 20%;
}

.tongdian {
    display: flex;
    margin-bottom: 100px;
    margin-left: 5%;
}
</style>