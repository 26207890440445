<template>
  <div style="font-family: 'A';">
    <div style="position: relative;">
      <img alt="Vue logo" src="https://qiniu.shunmingelectronic.com/guanwang/product/IST_28936_07550.jpg"
        style="width: 100%; height: auto; opacity: 1;">
      <div class="home-page-text">
        <div style="font-size: 1rem; margin-top: 20px;color: #e4ecff;">精准智控，慧创未来</div>
      </div>
      <div
        style=" position: absolute;top: 10%;left: 10%;color: white;font-size: 1rem;font-family:'A', Courier, monospace;">
        舜铭电子信息科技有限公司</div>
      <div
        style=" position: absolute;top: 30%;left: 10%;color: white;font-size: 1rem;font-family:'A', Courier, monospace;">
        为您提供物联网整体解决方案</div>
    </div>
    <!-- 我们的服务展示区域 -->
    <div style="margin-top: 50px;;margin-bottom: 110px;">
      <div style="font-size: 2rem;font-weight: 700;color: #1296db;">我们能为您提供什么</div>
      <div style="margin-top: 30px;  margin-bottom: 50px;">
        <div class="following-services-area">
          <div class="image-style">
            <img src="../../assets/ludeng.png" alt="SVG Image" width="180" height="180" />
          </div>
          <div style="padding: 0px 10%; font-size: 1rem; font-weight: 600; color: #1296db;">
            针对城市照明路灯以及园林景观灯，提供故障实时报警、远程集中控制、单灯节能管理，并支持能源消耗统计，助力智慧城市高效节能管理与运营优化。
          </div>
        </div>
        <div class="following-services-area">
          <div class="image-style">
            <img src="../../assets/meter.png" alt="SVG Image" width="200" height="200" />
          </div>
          <div style="padding: 0px 10%; font-size: 1rem; font-weight: 600; color: #1296db;">
            可提供具备超高计量精度和出色的线性度表现的计量模块，确保在各种复杂工况下仍可以提供稳定、可靠的计量结果，满足工业级别的要求。
          </div>
        </div>
        <div class="following-services-area">
          <div class="image-style">
            <img src="../../assets/yunpingtai.png" alt="SVG Image" width="180" height="180" />
          </div>
          <div style="padding: 0px 10%; font-size: 1rem; font-weight: 600; color: #1296db;">
            凭借十余年物联网整体解决方案的研发经验，推动各行业的数字化转型与智能化升级。帮助传统行业提升效率和竞争力，为客户提供高效、智能的解决方案。
          </div>
        </div>
      </div>
    </div>




    <!-- 产品图片区域 -->
    <div style="background-color: #fff;padding-bottom: 40px;">
      <div class="product-area-title">相关产品</div>
      <div class="product-jj">
        <div>
          <img src="https://qiniu.shunmingelectronic.com/guanwang/lightImage/lightplatform.png" width="60%" />
          <div class="product-kind-name">慧光路灯智能云平台</div>
          <div style="padding: 50px 0px;"><span class="button-style"
              @click="handClick('products', { categoryId: 1 })">了解更多</span>
          </div>
        </div>
      </div>
      <div class="product-jj">
        <div>
          <img src="https://qiniu.shunmingelectronic.com/guanwang/meter_web.png" width="60%" />
          <div>
            <div class="product-kind-name">智控仪表数据云平台</div>
            <div style="padding: 50px 0px;"><span class="button-style"
                @click="handClick('products', { categoryId: 2 })">了解更多</span></div>
          </div>
        </div>
      </div>
      <div class="product-jj">
        <div>
          <img src="https://qiniu.shunmingelectronic.com/irrigation/image/wulianwangshengji.png" width="50%" />
        </div>
        <div>
          <div>
            <div class="product-kind-name">传统行业物联网改造升级</div>
            <div style="padding: 50px 0px;"><span class="button-style"
                @click="handClick('products', { categoryId: 3 })">了解更多</span></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { jumpPage } from '@/utils/public';
import { getProductInfo } from '../../utils/auth';
export default {
  name: 'HomeView',
  data() {
    return {
      productList:
        [
          { imgUrl: "/assets/meterFlow.jpg", productname: 'Electromagnetic Flowmeter' },
          { imgUrl: "/assets/wojie.png", productname: 'Vortex Flowmeter' },
          { imgUrl: "/assets/csb.png", productname: 'Ultrasonic Flowmeter' },
          { imgUrl: "/assets/wolun.png", productname: 'Turbine Flowmeter' }
        ],
      categoryList: []
    }
  },
  methods: {
    handClick(val, query) {
      jumpPage(val, query);
    }
  },
  mounted() {
    this.categoryList = getProductInfo();
    console.log(this.categoryList);
  }
}
</script>

<style scoped>
.home-page-text {
  position: absolute;
  top: 50%;
  width: 100%;
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-align: center
}

.product-jj {
  margin-top: 0px;

}

.product-area-title {
  color: #1296db;
  font-size: 50px;
  font-weight: 700;
  margin-top: 80px;
}

.product-kind-name {
  font-size: 2rem;
  margin-top: 30px;
  color: #5daeff;
  font-weight: bold;

}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.following-services-area {
  .image-style{
    padding: 0px 10%;  font-size: 22px; font-weight: 600; color: #1296db;margin-top: 60px;
  }
  width: 100%;
  cursor: pointer;
  &:hover {
    background: #96c5ff1a;
  }
}

.product-image-style {
  cursor: pointer;
  width: 250px;
  height: 250px;

  .product-image-size-style {
    padding: 10px;

    &:hover {
      width: 100%;
      height: 100%;
    }
  }

  .product-name-style {
    font-size: 28px;
    color: #17abab;
    font-family: math;
  }
}

.iot-description-style {
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 30px;
  font-size: 22px;
  font-family: math;

}
</style>
