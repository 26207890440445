<template>
  <div>
    <div style="position: relative;">
      <img alt="Vue logo" src="https://qiniu.shunmingelectronic.com/guanwang/product/productBanner.jpg"
        style="width: 100%; height: auto; opacity: 1;">
      <div class="home-page-text">
        <div style="font-size: 50px; margin-top: 10px;">我们的产品</div>
      </div>
    </div>
    <div class="tabs">
      <div v-for="(product, index) in productInfo" :key="index" @click="selectTab(product.categoryId)" class="tab"
        :class="{ active: isActiveTab(product.categoryId) }">
        {{ product.categoryEnName }}</div>
    </div>
    <div class="tab-content">
      <FlowMeterProduct  v-if="activeTab===2"/>
      <IotProduct  v-if="activeTab===3"/>
      <LightingProduct v-if="activeTab===1"/>
     <!-- <div v-if="activeTab===1">1</div>
     <div v-if="activeTab===2">2</div>
     <div v-if="activeTab===3">3</div> -->
    </div>
  </div>
</template>

<script>
import FlowMeterProduct from './product/FlowMeterProduct.vue';
import IotProduct from './product/IotProduct.vue';
import LightingProduct from './product/LightingProduct.vue';
export default {
  name: 'ProductsView',
  components:{
    FlowMeterProduct,IotProduct,LightingProduct
  },
  data() {
    return {
      activeTab: 1, // 初始化为第一个Tab
      productList: [],
      matchedProduct: null,
      productInfo:[]
    };
  },
  methods: {
    selectTab(index) {
      const matchedProduct = this.productInfo.find(product => product.categoryId === index);
      console.log(matchedProduct);
      console.log(matchedProduct);
      this.matchedProduct = matchedProduct;
      this.productList = matchedProduct.products;
      this.activeTab = index;
    },
    isActiveTab(index) {
      return this.activeTab === index;
    },
    jumpProductDetail(product) {
      let link = "/productsDetail";
      this.$router.push({ path: link, query: { productId: product.productId, productEnName: product.productEnName, categoryId: this.matchedProduct.categoryId, categoryEnName: this.matchedProduct.categoryEnName } });
    }
  },
  watch: {

  },
  mounted() {
    this.productInfo = [{ categoryEnName: '慧光路灯智能云平台', categoryId: 1 }, { categoryEnName: '智控仪表数据云平台', categoryId: 2 }, { categoryEnName: '传统行业物联网改造升级', categoryId: 3 }]
    let initActiveTab = this.$route.query.categoryId ? this.$route.query.categoryId : this.productInfo[0].categoryId;
    this.selectTab(parseInt(initActiveTab));

  },
  updated() {

  }
}
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: center;
  /* 居中对齐 */
  margin-top: 20px;
}

.tab {
  padding: 15px 60px;
  /* 增大宽度 */
  cursor: pointer;
  border-bottom: 3px solid transparent;
  /* 初始状态下隐藏底部边框 */
  font-size: 22px;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: darkgray;
}

.tab.active {
  border-bottom: 2px rgb(73, 109, 219) solid;
  /* 初始状态下隐藏底部边框 */
  color: rgb(73, 109, 219);
}

.tab-content {
  padding: 30px 10%;
  border: none;

  .product-container {
    padding: 30px 0px;
    border-bottom: 2px #b9c7ff solid;
    display: flex;
  }

  .product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    /* 图片和名称之间的间距 */
  }

  .product-item {
    width: calc(33.333% - 100px);
    box-sizing: border-box;
    text-align: center;
    border: #ffffff 2px solid;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* 鼠标悬停时的边框阴影 */
    overflow: hidden;
    /* 防止放大的图片溢出容器 */

  }

  .product-image {

    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
    /* 添加平滑的过渡效果 */
  }

  .product-item:hover .product-image {
    transform: scale(1.1);
    /* 鼠标悬停时放大图片 */
  }

  .product-item:hover .product-name {
    background-color: #e9e9e9;
    color: #94c9f2;
  }

  .product-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    /* 鼠标悬停时的边框阴影 */
  }

  .product-name {
    font-size: 20px;
    background-color: #94c9f2;
    color: white;
    padding-top: 30px;
    height: 60px;
    font-family: math;
  }

}

.details-button {
  padding: 8px 40px;
  color: #fff;
  font-size: 20px;
  border-radius: 20px;
  background-color: #87a8ff;
  border: 2px #ffffff solid;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
    border: 2px #87a8ff solid;
    color: #87a8ff;
  }
}
</style>
