<template>
    <div id="app">
        <!-- 顶部导航栏 -->
        <div class="top-container">
            <div style="display: flex; align-items: center;">
                <img src="https://qiniu.shunmingelectronic.com/guanwang/logo1.png" alt="Company Logo"
                    style="height: 50px; margin-left: 20px;">
            </div>
            <div style="height: 100%; display: flex; align-items: center;">
                <div class="nav-item" @mouseover="showDropdown('home')" @mouseleave="hideDropdown('home')">
                    <a href="#" class="nav-link" @click="handlerClick()">首页</a>
                </div>
                <div class="nav-item" @mouseover="showDropdown('about')" @mouseleave="hideDropdown('about')">
                    <a href="#" class="nav-link" @click="handlerClick('about')">关于我们</a>
                </div>
                <div class="nav-item" @mouseover="showDropdown('products')" @mouseleave="hideDropdown('products')">
                    <a href="#" class="nav-link" @click="handlerClick('products')">产品</a>
                    <div v-if="activeDropdown === 'products'" class="dropdown-content" style="width: 1980%;">
                        <a v-for="(product, index) in productInfo" :key="index" href="#"
                            @click="handlerClick('products', { categoryId: product.categoryId })">{{
                                product.categoryEnName }}</a>
                    </div>
                </div>
                <div class="nav-item" @mouseover="showDropdown('contact')" @mouseleave="hideDropdown('contact')">
                    <a href="#" class="nav-link" @click="handlerClick('contact')">联系我们</a>
                </div>
            </div>
            <span
                style="text-align: right; margin-right: 30px; line-height: 80px; color: rgb(222 246 255); font-size: 30px; font-family: fangsong; font-weight: 900;     background: linear-gradient(to right, rgb(109, 179, 242), rgb(193 217 255)) text; -webkit-background-clip: text; color: transparent;">舜铭电子信息科技有限公司


            </span>
        </div>

        <!-- 路由视图 -->
        <div class="router-view-container">
            <router-view></router-view>
        </div>
        <div style="background-color: #000000;">
            <div
                style="height: 100px; margin-top: 20px;  display: flex; align-items: center; justify-content: center;">
                <a style="color: aliceblue; margin-right: 30px; text-decoration: none;" href="https://beian.miit.gov.cn"
                    target="_blank">豫ICP备2024070819号-1</a>
                <img src="../assets/batb.png" style="width: 18px; height: 20px; margin-right: 10px;" />
                <a style="color: aliceblue; text-decoration: none;"
                    href="https://beian.mps.gov.cn/#/query/webSearch?code=41030502000977" rel="noreferrer"
                    target="_blank">豫公网安备41030502000977</a>
            </div>
            <div
                style="text-align: center; width: 100%;font-size: 18px; line-height: 60px; color: #ffffff; height: 60px;">
                {{ copyright }}
            </div>
        </div>



    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            gsxx: `ShunMing International`,
            copyright: '版权所有：河南舜铭电子信息科技有限公司 ©2024 ',
            activeDropdown: null,
            productInfo: [{ categoryEnName: '慧光路灯智能云平台', categoryId: 1 }, { categoryEnName: '智控仪表数据云平台', categoryId: 2 }, { categoryEnName: '传统行业物联网改造升级', categoryId: 3 }]
        }
    },
    methods: {
        showDropdown(dropdownName) {
            this.activeDropdown = dropdownName;
        },
        hideDropdown(dropdownName) {
            if (this.activeDropdown === dropdownName) {
                this.activeDropdown = null;
            }
        },
        handlerClick(val, params) {
            let link = "/";
            if (val) link += val;
            this.$router.push({ path: link, query: params });
        },

    },
    mounted() {
    }
}
</script>

<style scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.top-container {
    width: 100%;
    height: 70px;
    background-color: rgb(33 33 33);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.router-view-container {
    position: relative;
    z-index: 1;
}

.nav-item {
    margin: 0px 20px;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.nav-link {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
}

.nav-item::after {
    content: '';
    height: 4px;
    background-color: transparent;
    width: 120%;
    position: absolute;
    bottom: -10px;
    left: -10%;
    transition: background-color 0.3s, bottom 0.3s;
}

.nav-item:hover::after {}

.nav-item:hover .nav-link {
    color: rgb(110, 161, 237);
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgb(77 77 77 / 66%);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    top: 100%;
    left: 0;
}

.dropdown-content a {
    color: rgb(239, 239, 239);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown-content a:hover {
    background-color: #6a6a6a80;
}
</style>